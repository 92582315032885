import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"

import Image1 from 'components/ServicesImages/img1'
import Image2 from 'components/ServicesImages/img2'
import Image3 from 'components/ServicesImages/img3'
import Image4 from 'components/ServicesImages/img4'
import Image5 from 'components/ServicesImages/img5'
import Image6 from 'components/ServicesImages/img6'
import Image7 from 'components/ServicesImages/img7'
import Image8 from 'components/ServicesImages/img8'

const ServicesPage = ({ intl }) => {

  const isThai = () => {
    return (intl.locale === "th")
  }

  return (
  <Layout>

    <SEO
          site={siteMetadata}
          title="Our Services"
          description="Application essays, placement tests, interviews and life abroad support. Contact us to enhance your profiles for university, job and scholarships applications. "
           />

  <section id="thumbnail">
    <div className="center-cropped">
        <img src={require('../images/services.jpeg')} alt="plan.jpeg" />
        <div className={isThai? 'centered th-iphone-x' : 'centered'}>
      <p id="thumbnailText"><em><FormattedMessage id="s_let_us_support" /></em></p>
    </div>
    </div>
    
  </section>

  <section id="ourServices" className="centeredText">
  <div className="container m-r-auto m-l-auto">
                <h1><FormattedMessage id="s_our_services" /></h1>
                <p><FormattedHTMLMessage id="s_our_services_p" /> </p>
  </div>
  </section>    

  <section id="stepsSection">   
    <div className="container">
    <div className="centeredText">
      <h2 className="centeredText text-align-center"><FormattedMessage id="s_4_steps" /></h2>
      <h2><FormattedMessage id="s_4_steps_p" /></h2>
        
    </div>

      <div className="row">

            <div className="col-lg-4 col-12 mb-5">
                <Image1 alt="plan.jpeg" />
            </div>
            <div id="step_1" className="col-lg-8 col-12">
                <h3><FormattedMessage id="s_step_1" /></h3>
                <p><FormattedMessage id="s_step_1_p" />&nbsp;</p>
            </div>
            
            <div className="col-lg-4 col-12 mb-5">
            <Image2 alt="plan.jpeg" />
            </div>
            <div id="step_2" className="col-lg-8">
              <h3><FormattedMessage id="s_step_2" /> &nbsp;</h3>
              <p><FormattedMessage id="s_step_2_p" /> </p>
            </div>

            <div className="col-lg-4 col-12 mb-5">
            <Image3 alt="plan.jpeg" />
            </div>
            <div id="step_3" className="col-lg-8 col-12">
            <h3><FormattedMessage id="s_step_3" />&nbsp; </h3>
                <p><FormattedMessage id="s_step_3_p" /> </p>
            </div>
            
            <div className="col-lg-4 col-12 mb-5">
            <Image4 alt="plan.jpeg" />
            </div>
            <div id="step_4" className="col-lg-8 col-12">
            <h3><FormattedMessage id="s_step_4" />&nbsp; </h3>
                    <p><FormattedMessage id="s_step_4_p" /> </p>
            </div>
            <div className="col-lg-4 col-12 mb-5">
            <Image5 alt="plan.jpeg" />
            </div>

            <div id="step_5" className="col-lg-8 col-12">
              <h3><FormattedMessage id="s_step_5" /> &nbsp;</h3>
                <p><FormattedMessage id="s_step_5_p" /> </p>
            </div>
          </div>
          </div>
          </section>

          <section id="stepsSection" className="centeredText">   
    <div className="container">
    <div className="centeredText">
      <h2 className="centeredText text-align-center"><FormattedMessage id="s_6_steps" /></h2>
         <p><center><FormattedMessage id="s_6_steps_p" /> </center> &nbsp;</p>
         
    </div>

      <div className="row">

            <div className="col-lg-4 col-12 mb-5">
                <Image6 alt="plan.jpeg" />
            </div>
            <div id="step_6" className="col-lg-8 col-12">
                <h3><FormattedMessage id="s_step_6" /></h3>
                <p><FormattedMessage id="s_step_6_p" />
                <ul>
                  &nbsp;
                 <li><FormattedMessage id="s_step_cv" /></li>
                 <li><FormattedMessage id="s_step_pf" /></li>
                 <li><FormattedMessage id="s_step_li" /></li>
                 <li><FormattedMessage id="s_step_cl" /></li>
                </ul> &nbsp;</p>
            </div>
            
            <div className="col-lg-4 col-12 mb-5">
              <Image7 alt="plan.jpeg" />
            </div>
            <div id="step_7" className="col-lg-8">
              <h3><FormattedMessage id="s_step_7" /></h3>
              <p><FormattedMessage id="s_step_7_p" /> <br/>
              <ul>
                &nbsp;
                 <li><FormattedMessage id="s_step_cv" /></li>
                 <li><FormattedMessage id="s_step_pf" /></li>
                 <li><FormattedMessage id="s_step_li" /></li>
                 <li><FormattedMessage id="s_step_sp" /></li>
               </ul>&nbsp;</p>
            </div>

            <div className="col-lg-4 col-12 mb-5">
              <Image8 alt="plan.jpeg" />
            </div>
            <div id="step_8" className="col-lg-8 col-12">
            <h3><FormattedMessage id="s_step_8" /> </h3> 
                <p><FormattedMessage id="s_step_8_p" /><br/>
                <ul >&nbsp;
                 <li><FormattedMessage id="s_step_cv" /></li>
                 <li><FormattedMessage id="s_step_pf" /></li>
                 <li><FormattedMessage id="s_step_li" /></li>
                 <li><FormattedMessage id="s_step_cl" /></li>
                </ul>&nbsp;</p>
            </div>
          
          </div>
          </div>
          </section>
</Layout>
  )
}
export default injectIntl(ServicesPage)